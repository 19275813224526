<template>
  <div style="height: inherit">
    <div class="body-content-overlay" :class="{ show: mqShallShowLeftSidebar }" @click="mqShallShowLeftSidebar = false" />
    <transition mode="out-in">
      <router-view
        @mqShallShowLeftSidebar="mqShallShowLeftSidebar = $event"
        @onSubmit="onSubmit"
        @langChange="handlerGetDilData($event)"
        @copyProduct="handlerCopyProduct"
        @removeProduct="handlerRemove($event)"
        :defaultDil="defaultDil"
        :diller="diller"
        :form="form"
        :scrollbarSettings="scrollbarSettings"
        :taglar="taglar"
        :show="show"
      />
    </transition>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <urun-tanimla-sol-menu
        :class="{ show: mqShallShowLeftSidebar }"
        :form="form"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import UrunTanimlaSolMenu from './../component/urunTanimlaSolMenu.vue';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ref, toRefs, reactive, watch, onMounted, nextTick, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
import { useRouter } from '@core/utils/utils';
export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    UrunTanimlaSolMenu,
  },

  setup() {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.mqShallShowLeftSidebar = ref(false);
    expo.scrollbarSettings = ref({
      maxScrollbarLength: 150,
    });
    expo.show = ref(false);

    expo.taglar = ref([]);
    expo.form = ref({
      _id: null,
      k_no: null,
      kategori_k_no: null,
      bolge_k_no: null,
      icerik: {},
      video: null,
      anasayfa: false,
      gorseller: [],
      gunler: [],
      aylar: [],
      seans_alis_saat: [],
      fiyat_politikasi: 'tekil',
      tekil_fiyat: {
        yetiskin: {
          en_az: 0,
          en_cok: 0,
          fiyat: 0,
        },
        cocuk: {
          en_az: 0,
          en_cok: 0,
          fiyat: 0,
        },
        bebek: {
          en_az: 0,
          en_cok: 0,
          fiyat: 0,
        },
      },
      grup_fiyat: {
        en_az: 0,
        en_cok: 0,
        fiyat: 0,
        arti_kisi_fiyat: 0,
      },
      kur: null,
      indirim_turu: null,
      indirim_oran: 0,
      dahil_hizmet: [],
      haric_hizmet: [],
      iptal_misafir: [],
      iptal_firma: [],
      taglar: [],
      rehber_sesli_yazili: false,
      rehber_lisanslimi: false,
      rehber_sofor_hizmeti: false,
      rehber_bildigi_diller: [
        {
          diller: 'Türkçe',
          dil: 'turkce',
          sahis: false,
          sesli: true,
          okuma: false,
        },
        {
          diller: 'Almanca',
          dil: 'almanca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'İngilizce',
          dil: 'ingilizce',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Rusça',
          dil: 'rusca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Lehçe',
          dil: 'lehce',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Fransızca',
          dil: 'fransizca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Arapça',
          dil: 'arapca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
      ],
      guzergah_kodu: null,
      asgari_yas: null,
      maksimum_kisi: null,
      maksimum_yas: null,
      toplam_sure: null,
      toplam_sure_turu: null,
      rezervasyon_kapama: null,
      statu: 3,
      seo: false,
    });

    const createIcerikDilUret = () => {
      expo.diller.value.forEach((dil) => {
        if (!expo.form.value.icerik.hasOwnProperty(dil.lang)) {
          expo.form.value.icerik[dil.lang] = { urun_adi: null, on_aciklama: null, aciklama: null, program: null };
        }
      });
    };

    createIcerikDilUret();

    onMounted(async () => {
      if (route.value.params.k_no) {
        expo.show.value = true;
        await store.dispatch('tagListele');
        await store.dispatch('ekstraHizmetListele');
        await store.dispatch('iptalPolitikaListele');
        await store.dispatch('kurTurleriListele');

        expo.taglar.value = await store.getters.getTaglar;

        const urunler = store.getters.getUrunler;
        const urun = urunler.find((x) => x.k_no == route.value.params.k_no);

        store.commit('SET_URUN', urun);
        store.commit('SET_URUN_ROUTE', { k_no: urun.k_no });
        expo.form.value = { ...urun };
        expo.show.value = false;
        createIcerikDilUret();
      } else {
        createIcerikDilUret();
      }
    });

    expo.onSubmit = () => {
      const base_url = expo.form.value.k_no == null ? 'urunBilgiEkle' : 'urunBilgiGuncelle';
      store
        .dispatch(base_url, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.form.value = { ...expo.form.value, ...res.data.data };
            if (expo.form.value.k_no != null) {
              if (res.data.type != 'update') router.push({ name: 'urun-guncelle', params: { k_no: expo.form.value.k_no } });
              store.commit('SET_URUN', expo.form.value);
              store.commit('SET_URUN_ROUTE', { k_no: expo.form.value.k_no });
            }
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.handlerCopyProduct = () => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Ürünü Kopyalamak istediğinizden emin misiniz !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
        }
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('urunSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Silme Başarılı',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
              router.push({ name: 'urun-listele' });
            }
          });
        }
      });
    };

    const urunler = computed(() => store.getters.getUrun);

    watch(
      () => urunler.value,
      (val) => {
        expo.form.value = { ...val };
        createIcerikDilUret();
      }
    );
    return { ...expo };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('SET_URUN', {});
    this.$store.commit('SET_URUN_ROUTE', { k_no: null });
    next();
  },
};
</script>

<!-- <script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import UrunTanimlaSolMenu from './../component/urunTanimlaSolMenu.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { mapGetters } from 'vuex';
import { diller } from '@core/mixins/ui/diller';
export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    UrunTanimlaSolMenu,
  },
  computed: {
    ...mapGetters(['getUrun']),
  },
  mixins: [diller],
  data() {
    return {
      mqShallShowLeftSidebar: false,
      scrollbarSettings: {
        maxScrollbarLength: 150,
      },
      taglar: [],
      form: {
        _id: null,
        k_no: null,
        kategori_k_no: null,
        bolge_k_no: null,
        icerik: {},
        // dil: this.$store.getters.getDilAyarlari.varsayilan,
        // urun_adi: null,
        // on_aciklama: null,
        // aciklama: null,
        // program: null,
        video: null,
        anasayfa: false,
        gorseller: [],
        gunler: [],
        aylar: [],
        seans_alis_saat: [],
        fiyat_politikasi: 'tekil',
        tekil_fiyat: {
          yetiskin: {
            en_az: 0,
            en_cok: 0,
            fiyat: 0,
          },
          cocuk: {
            en_az: 0,
            en_cok: 0,
            fiyat: 0,
          },
          bebek: {
            en_az: 0,
            en_cok: 0,
            fiyat: 0,
          },
        },
        grup_fiyat: {
          en_az: 0,
          en_cok: 0,
          fiyat: 0,
          arti_kisi_fiyat: 0,
        },
        kur: null,
        indirim_turu: null,
        indirim_oran: 0,
        dahil_hizmet: [],
        haric_hizmet: [],
        iptal_misafir: [],
        iptal_firma: [],
        taglar: [],
        rehber_sesli_yazili: false,
        rehber_lisanslimi: false,
        rehber_sofor_hizmeti: false,
        rehber_bildigi_diller: [
          {
            diller: 'Türkçe',
            dil: 'turkce',
            sahis: false,
            sesli: true,
            okuma: false,
          },
          {
            diller: 'Almanca',
            dil: 'almanca',
            sahis: false,
            sesli: false,
            okuma: false,
          },
          {
            diller: 'İngilizce',
            dil: 'ingilizce',
            sahis: false,
            sesli: false,
            okuma: false,
          },
          {
            diller: 'Rusça',
            dil: 'rusca',
            sahis: false,
            sesli: false,
            okuma: false,
          },
        ],
        guzergah_kodu: null,
        asgari_yas: null,
        maksimum_kisi: null,
        maksimum_yas: null,
        toplam_sure: null,
        toplam_sure_turu: null,
        rezervasyon_kapama: null,
        statu: 3,
      },
    };
  },
  created() {
    this.handlerUrunBilgileri();
    this.createIcerikDilUret();
  },
  methods: {
    async handlerUrunBilgileri() {
      if (this.$route.params.k_no) {
        await this.$store.dispatch('tagListele');

        await this.$store.dispatch('ekstraHizmetListele');
        await this.$store.dispatch('iptalPolitikaListele');
        await this.$store.dispatch('kurTurleriListele');
        this.taglar = await this.$store.getters.getTaglar;

        const urunler = this.$store.getters.getUrunler;
        const urun = urunler.find((x) => x.k_no == this.$route.params.k_no);

        this.$store.commit('SET_URUN', urun);
        this.$store.commit('SET_URUN_ROUTE', { k_no: urun.k_no });
        this.form = { ...urun, dil: this.$store.getters.getDilAyarlari.varsayilan };

        this.handlerGetDilData(this.form.dil);
      }
    },

    createIcerikDilUret() {
      this.diller.forEach((dil) => {
        console.log(dil);
        this.form.icerik[dil.lang] = { urun_adi: null, on_aciklama: null, aciklama: null, program: null };
      });
    },

    // async handlerGetDilData(dil) {
    //   if (Object.keys(this.form).length > 0) {
    //     let item = this.form.icerik.find((x) => x.dil == dil);
    //     this.form.dil = dil;
    //     if (item != undefined) {
    //       this.form.urun_adi = item.urun_adi;
    //       this.form.on_aciklama = item.on_aciklama;
    //       this.form.aciklama = item.aciklama;
    //       this.form.program = item.program;
    //     } else {
    //       this.form.urun_adi = null;
    //       this.form.on_aciklama = null;
    //       this.form.aciklama = null;
    //       this.form.program = null;
    //     }
    //   }
    // },
    onSubmit() {
      const base_url = this.form._id == null ? 'urunBilgiEkle' : 'urunBilgiGuncelle';
      this.$store
        .dispatch(base_url, this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.form = { ...this.form, ...res.data.data };
            if (this.form._id != null) {
              if (res.data.type != 'update') this.$router.push({ name: 'urun-guncelle', params: { k_no: this.form.k_no } });
              this.$store.commit('SET_URUN', this.form);
              this.$store.commit('SET_URUN_ROUTE', { k_no: this.form.k_no });
            }
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
    handlerCopyProduct() {
      this.$swal({
        title: 'Uyarı',
        text: 'Ürünü Kopyalamak istediğinizden emin misiniz !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
        }
      });
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('urunSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
              this.$router.push({ name: 'urun-listele' });
            }
          });
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('SET_URUN', {});
    this.$store.commit('SET_URUN_ROUTE', { k_no: null });
    next();
  },
  watch: {
    getUrun: {
      handler(val) {
        this.form = { ...val, dil: this.$store.getters.getDilAyarlari.varsayilan };
        // this.handlerGetDilData(this.form.dil);
      },
    },
  },
};
</script> -->

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo.scss';
</style>
